export const Contribution = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='contribution' />
    }

    return (
        <div id='contribution' className={(project.title === 'Anthos' || project.title === 'zkEVM') ? 'yellowBg' : ''}>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">My Contribution</h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text ">{project.contribution.map((solution, rIdx) => <p className={(project.title === 'Anthos' || project.title === 'zkEVM') ? '' : 'grey-text'} key={rIdx}>{solution}</p>)}</div>
                    </div>
                </div>
                {(project.title === 'Social Capital' || project.title === 'Gatsby TV') && (
                    <div className="row image_container top-space">
                        {project.contribution_img.map((imgSrc, idx) => (
                            <div className="col-sm-12" key={idx}>
                                <video loop muted autoPlay className="part-img">
                                    <source src={imgSrc} type="video/mp4" />
                                </video>
                            </div>
                        ))}
                    </div>
                )}
                {project.title === 'Anthos' && (
                    <div className="row image_container top-space">
                        {project.contribution_img.map((imgSrc, idx) => (
                            <div className="col-sm-12" key={idx}>
                                <img src={imgSrc} alt="" className="part-img" />
                            </div>
                        ))}
                    </div>
                )}
                {project.title === 'zkEVM' && <div className="row image_container top-space">
                    {project.contribution_img.map((imgSrc, idx) => (
                        <div className="col-sm-6" key={idx}>
                            { idx === 0 ? (
                                <img src={imgSrc} alt="" className="part-img" />
                            ) : (
                                <video loop muted autoPlay className="part-img">
                                    <source src={imgSrc} type="video/mp4" />
                                </video>
                            )}
                        </div>
                    ))}
                </div>
                }
            </div>
        </div>
    )
}
