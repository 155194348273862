export const Navigation = (props) => {
  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            <img src="img/nav_menu_open.svg" alt="" className="nav-menu-icon" />
          </button>
          <a href='/' className='navbar-brand page-scroll'>
            <div>
              <img src="img/logo.png" alt="" className="logo-img"/>
              Gatsby
            </div>
          </a>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >

          <ul className='nav navbar-nav navbar-left menu-context'>
            <li>
              <a href='#portfolio' className='page-scroll'>
                Portfolio
              </a>
            </li>
            <li>
              <a href='#services' className='page-scroll'>
                Skills & Experience
              </a>
            </li>
            
            <li>
              <a href='#testimonials' className='page-scroll'>
                Clients
              </a>
            </li>
            <li>
              <a href='#contact' className='page-scroll'>
                Contact
              </a>
            </li>
          </ul>
          
          <a href='#contact' className='btn btn-custom btn-lg page-scroll navbar-right btn-start-project' >
            Start Project
          </a>

        </div>
        
      </div>
    </nav>
  )
}
