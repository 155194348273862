export const Testimonials = (props) => {
  return (
    <div id='testimonials'>
      <div className='container'>
        <div className='row section-title'>
          <div className="col-sm-7"><h2>Brands I've Worked With In The Past</h2></div>
          <div className="col-sm-5 section-title-description"> </div>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-2 col-sm-5'>
                  <div className='testimonial'>
                    <img src={d.img} alt='' />
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
