export const Header = (props) => {
  return (
    <header id='header'>
      <div className='intro'>
        <div className='overlay'>
          <div className='container'>
            <div className='row'>
              <div>
                <div className="col-sm-6 intro-text">
                  <h1>
                    {props.data ? props.data.title : 'Loading'}
                    <span></span>
                  </h1>
                  <p>{props.data ? props.data.paragraph : 'Loading'}</p>
                  <div>
                    <a href='#contact' className='btn btn-custom btn-lg page-scroll talk-btn' >
                      Let's talk
                    </a>
                    <a href='#portfolio' className='btn btn-custom btn-lg page-scroll portfolio-btn' >
                      Portfolio <img src="img/p_arrow.svg" alt="" />
                    </a>
                  </div>
                </div>
                <div className='col-sm-6 intro-img'>
                  <img src={"img/main_photo.png"} className='img-responsive' alt={"Gatsby"} />
                  <span>{props.data ? `“${props.data.logoTitle}“`: 'Loading'}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
