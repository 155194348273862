import React from 'react';
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Header } from './component/header';
import { Problems } from './component/problem';
import { Research } from './component/research';
import { Solution } from './component/solution';
import { Contribution } from './component/contribution';
import { Concluding } from './component/concluding';
import { Design } from './component/design';
import { Projects } from './component/projects';
import SmoothScroll from "smooth-scroll";

function Project(props) {
    const { id: projectName } = useParams();

    const [project, setProject] = useState(null);
    const [otherProjects, setOtherProjects] = useState([]);

    useEffect(() => {
        if (props.data) {
            const projectData = props.data.filter((eachProject) => eachProject.title === projectName);
            const otherProjectData = props.data.filter((eachProject) => eachProject.title !== projectName);
            setProject(projectData[0]);
            setOtherProjects(otherProjectData);
        }
        const scroll = new SmoothScroll('a[href*="#"]', {
            speed: 1000,
            speedAsDuration: true,
        });
        const anchor = document.querySelector('#page-top');
        scroll.animateScroll(anchor);
    }, [props, projectName]);

    return (
        <div id="project-details">
            <Header project={project} />
            <Problems project={project} />
            {project && project.thought && <Research project={project} />}
            <Solution project={project} />
            <Contribution project={project} />
            {project && project.design && <Design project={project} /> }
            {project && project.designConsiderations && <Concluding project={project} /> }
            <Projects otherProjects={otherProjects} />
        </div>
    )
};

export default Project;
