export const Services = (props) => {
  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='row section-title'>
          <div className="col-sm-6"><h2 className="">Skills & Experience</h2></div>
          <div className="col-sm-6 section-title-description"> </div>
        </div>
        <p>Experience</p>
        <div className='row'>
          {props.data
            ? props.data.experience.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-2 col-sm-5 service-item'>
                  <img src={d.icon} alt="" className="service-img"/>
                  <h3 className="service-text">{d.name}</h3>
                </div>
              ))
            : 'loading'}
        </div>
        <p>Skills</p>
        <div className='row'>
          {props.data
            ? props.data.skills.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-2 col-sm-5 service-item'>
                  <img src={d.icon} alt="" className="service-img"/>
                  <h3 className="service-text">{d.name}</h3>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
