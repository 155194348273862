import { useState, useEffect } from "react";
import { Routes, Route} from 'react-router-dom';
import { Navigation } from "./components/navigation";
import { Contact } from "./components/contact";
import Home from "./pages/Home/home";
import Project from './pages/ProjectDetails/project';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});

  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Routes>
        <Route path="/" element={<Home data={landingPageData}/>} />
        <Route path="/project/:id" element={<Project data={landingPageData.Portfolios}/>} />
      </Routes>
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
