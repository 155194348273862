export const Solution = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='solution' />
    }

    return (
        <div id='solution' className={(project.title === 'Anthos' || project.title === 'zkEVM') ? 'whiteBg' : ''}>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">Solution</h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text">{project.solution.map((solution, rIdx) => <p key={rIdx} className={`info-text ${(project.title === 'Anthos' || project.title === 'zkEVM') ? 'grey-text' : ''}`}>{solution}</p>)}</div>
                    </div>
                </div>
                <div className="row image_container">
                    { project.title === 'zkEVM' && project.solution_img.map((imgSrc, idx) => (
                        <div className="col-sm-12" key={idx}>
                            <video loop muted autoPlay className="part-img">
                                <source src={imgSrc} type="video/mp4" />
                            </video>
                        </div>
                    ))}
                    { project.title !== 'zkEVM' && project.solution_img.map((imgSrc, idx) => (
                        <div className="col-sm-6" key={idx}>
                            <img src={imgSrc} alt="" className="part-img" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
