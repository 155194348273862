import { useNavigate } from "react-router-dom";

export const Projects = (props) => {
    const { otherProjects } = props;
    const navigate = useNavigate();

    const onHandleMoveProjectDetail = (projectName) => {
        navigate(`/project/${projectName}`);
    }
  
    if (!otherProjects) {
        return <div id='portfolio' />
    }

    return (
        <div id='portfolio' className='text-center'>
            <div className='container'>
                <div className='section-title'>
                    <h2>Other Projects</h2>
                </div>
                <div className='portfolio-items'>
                {otherProjects.map((d, i) => (
                    <div key={`${d.title}-${i}`} className='col-sm-6 col-md-4 col-lg-4 portfolio-item'>
                        <div className="portfolio-inf">
                            <div className="project-header-text">
                                <p className="project-title">{d.title}</p>
                                <p className="project-description">{d.description}</p>
                            </div>
                            <div className="project-header-icon" onClick={() => onHandleMoveProjectDetail(d.title)}>
                                <img src="img/w_arrow.svg" alt="" />
                            </div>
                        </div>
                        <div className="portfolio-img cursor-pointer" onClick={() => onHandleMoveProjectDetail(d.title)}>
                            <img src={d.image} className='project-img' alt={d.title} />
                        </div>
                    </div>
                    ))
                }
                </div>
            </div>
        </div>
    )
}
