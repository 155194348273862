export const Concluding = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='concluding' />
    }

    return (
        <div id='concluding'>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">Design Considerations</h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text grey-text">
                            {project && project.designConsiderations.map((para, pIdx) => <p key={pIdx}>{para}</p>)}
                        </div>
                    </div>
                </div>
                {project.title === 'Gatsby TV' && <div className="row image_container">
                    {project.design_consideration_img.map((imgSrc, idx) => (
                        <div className="col-sm-12" key={idx}>
                            <img src={imgSrc} alt="" className="part-img" />
                        </div>
                    ))}
                </div>
                }
                {project.title === 'Social Capital' && <div className="row image_container">
                    {project.design_consideration_img.map((imgSrc, idx) => (
                        <div className="col-sm-6" key={idx}>
                            <img src={imgSrc} alt="" className="part-img" />
                        </div>
                    ))}
                </div>
                }
            </div>
        </div>
    )
}
