export const Research = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='research' />
    }

    return (
        <div id='research'>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">Thought Process: How I Thought My Way To A Solution
                        </h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text grey-text" dangerouslySetInnerHTML={{__html: project && project.thought}} />
                        {/* <div className="info-text">{project && project.thought.map((para, pIdx) => <p className=" grey-text" key={pIdx}>{para}</p>)}</div> */}
                    </div>
                </div>
                {project.title === 'Social Capital' ? (
                    <div className="row image_container">
                        {project.thought_img.map((imgSrc, idx) => (
                            <div className="col-sm-6" key={idx}>
                                <img src={imgSrc} alt="" className="part-img" />
                            </div>
                        ))}
                    </div>
                    ) : (
                    <div className="row image_container">
                        {project.thought_img.map((imgSrc, idx) => (
                            <div className="col-sm-4" key={idx}>
                                <img src={imgSrc} alt="" className="part-img" />
                            </div>
                        ))}
                    </div>
                    )
                }
            </div>
        </div>
    )
}
