export const Design = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='concluding' />
    }

    return (
        <div id='design'>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">Visual Design</h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text">{project.design}</div>
                    </div>
                </div>
                <div className="row image_container">
                    {project.design_img.map((imgSrc, idx) => (
                        <div className="col-sm-12" key={idx}>
                            <img src={imgSrc} alt="" className="part-img" />
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
