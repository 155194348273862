export const Header = (props) => {
    const { project } = props;

    if (!project) {
        return <header id='header'></header>
    }

    return (
        <header id='header'>
            <div>
                <div className='overlay'>
                    <div className='container'>
                        <div className="project-header">
                            <div className="project-nav">
                                <a href={'/'} className='nav-move-text'>Home</a>
                                <span className='nav-non-move-text'>{` > ${project.title}`}</span>
                            </div>
                            <span className='project-name'>{project.title}</span>
                            <span className='project-subtitle'>{project && project.subtitle}</span>
                            <div className='row'>
                                <div className="col-sm-6">
                                    <div className="project-detail-col">
                                        <span className="project-detail-header part-title">Brief</span>
                                        {project && project.brief.map((para, pIdx) => <p key={pIdx}>{para}</p>)}
                                    </div>
                                </div>
                                <div className='col-sm-6'>
                                    <div className="project-detail-col">
                                        <span className="project-detail-header part-title">Project Details</span>
                                        <div className="project-detail-sub-row">
                                            <span className="project-detail-sub-title">My Roles:</span>
                                            <span className="project-detail-sub-content">{project && project.roles}</span>
                                        </div>
                                        <div className="project-detail-sub-row">
                                            <span className="project-detail-sub-title">Tools I Used:</span>
                                            <span className="project-detail-sub-content">{project && project.tools}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
