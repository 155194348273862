export const Problems = (props) => {
    const { project } = props;

    if (!project) {
        return <div id='problems' />
    }

    return (
        <div id='problems'>
            <div className='container'>
                <div className='part-info'>
                    <div className="part-info-title"><h2 className="part-title">Problem</h2></div>
                    <div className="part-info-content">
                        <div className="line-mark"> </div>
                        <div className="info-text">{project.problem}</div>
                    </div>
                </div>
                {project.title === 'Anthos' ? (
                    <div className="row image_container">
                        {project.problem_img.map((imgSrc, idx) => (
                            <div className="col-sm-12" key={idx}>
                                <img src={imgSrc} alt="" className="part-img" />
                            </div>
                        ))}
                    </div>
                    ) : ( 
                        <div className="row image_container">
                            {project.problem_img.map((imgSrc, idx) => (
                                <div className="col-sm-6" key={idx}>
                                    <img src={imgSrc} alt="" className="part-img" />
                                </div>
                            ))}
                        </div>
                    )
                }
            </div>
        </div>
    )
}
