import React from 'react';
import { Header } from "./components/header";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";

function Home(props) {

    return (
        <>
            <Header data={props.data.Header} />
            <Gallery data={props.data.Portfolios}/>
            <Services data={props.data.Services} />
            <Testimonials data={props.data.Partners} />
        </>
    )
};

export default Home;
